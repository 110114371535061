import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { pageLinks } from '../lib/prefetch';
import NotFoundPage from '../modules/NotFound';

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'bridgeStatus',
        'error',
        'common',
      ])),
      dehydratedState: await pageLinks(locale)(),
      locale,
    },
    revalidate: 30 * 60,
  };
}

export default NotFoundPage;
