import { Typography } from '@oresundsbron/design-system';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { DefaultLink } from '~/components/Links/DefaultLink';
import { links, baseURL } from '~/lib/payLinks';

const NotFound = () => {
  const { t } = useTranslation(['error', 'common'], {
    nsMode: 'fallback',
  });

  const router = useRouter();
  const locale = (router.locale || 'en') as keyof typeof links;
  const homeUrl = `${baseURL}/${locale}`;

  return (
    <>
      <div className="flex min-h-page flex-col items-center justify-center px-4 md:px-0">
        <Typography
          variant="h2"
          as="h1"
          sx={{
            fontWeight: 'fontWeights.light',
            lineHeight: 'lineHeights.400',
          }}
        >
          {t('notFound.title')}
        </Typography>
        <Typography>
          {t('notFound.description')}
          <DefaultLink sx={{ color: 'colors.primary.600' }} href={homeUrl}>
            {t('notFound.homePage')}
          </DefaultLink>
        </Typography>
      </div>
    </>
  );
};

export default NotFound;
